import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import AppController from 'base/App.controller';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';
import MemberSearchDropdown from 'components/common/member-search/MemberSearchDropdown';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import { ICreateCSRProps } from 'components/certificate-management/types';
import DeleteCertificateModal from 'components/common/modals/delete-certificate/DeleteCertificateConfirmation';
import DeleteCertificateErrorModal from 'components/common/modals/delete-certificate/DeleteCertificateError';

import uploadIcon from '../../../images/upload.svg';
import DownloadIcon from '../../../images/downloadIcon.svg';
import DeleteIcon from '../../../images/deleteIcon.svg';

import formatDate from 'helpers/format-date/formatDate';

import { IRow } from 'components/common/types';
import { Itranslation } from 'types';

export const LIST_VIEW_TYPES = {
    CERTIFICATE: 'certificate',
    CSR: 'csr',
} as const;

type CertificateTabType = typeof LIST_VIEW_TYPES[keyof typeof LIST_VIEW_TYPES];

const useStyles = makeStyles(() => ({
    rootTab: {
        border: '1px solid transparent',
        '&.Mui-selected': {
            border: 'none',
            borderBottom: '3px solid',
        },
    },
    tabsflexContainer: {
        borderBottom: 'none',
    },
    tableWrapperRootCertificate: {
        '& .mainContainer': {
            marginTop: '0px',
        },
        '& .MuiTableCell-head': {
            textAlign: 'left',
        },
        '& .MuiTableCell-body': {
            textAlign: 'left',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        },
        '& .MuiTableCell-body:nth-child(-n+3)': {
            width: '18%',
            textAlign: 'left',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        },
        '& .MuiTableCell-body:last-child': {
            padding: 0,
        },
    },
    tableWrapperRootCSR: {
        '& .mainContainer': {
            marginTop: '0px',
        },
        '& .MuiTableCell-head': {
            textAlign: 'left',
        },
        '& .MuiTableCell-body': {
            textAlign: 'left',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        },
        '& .MuiTableCell-body:nth-child(-n+2)': {
            width: '25%',
            textAlign: 'left',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        },
        '& .MuiTableCell-body:last-child': {
            padding: 0,
            width: '10%',
        },
    },
}));

const CertificatesLanding: React.FC<ICreateCSRProps> = ({
    AppStore,
    CertificateManagementStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(
        AppStore.scope,
        'certificate-management',
    );
    const {
        allCertTabs,
        certTabValue,
        CertificatesTableWrapperStore,
        CSRsTableWrapperStore,
        DeleteCertificateErrorModalStore,
        DeleteCertificateModalStore,
        deleteCertificate,
        deleteCSR,
        downloadCertificate,
        downloadCSR,
        GenericErrorStore,
        getMemberId,
        getCertificates,
        getCSRs,
        handleCertTabChange,
        isAdmin,
        isDeleting,
        isDownloading,
        isMemberId,
        keyIdFilter,
        loadingCSR,
        loadingCertificate,
        resetState,
        updateKeyIdFilter,
        MemberSearchDropdownStore,
        sendToUploadCert,
    } = CertificateManagementStore!;
    const classes = useStyles();
    const [id, setId] = useState<string>('');

    useEffect(() => {
        if (isMemberId) {
            resetState();
            fetchDataByTabValue();
        }
    }, [getMemberId(), certTabValue]);

    const fetchDataByTabValue = async () => {
        certTabValue === LIST_VIEW_TYPES.CERTIFICATE ? await getCertificates() : await getCSRs();
    };

    const handleKeyIdChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        value === '' ? await _handleClear() : updateKeyIdFilter(certTabValue, value);
    };

    const _handleClear = async () => {
        updateKeyIdFilter(certTabValue, '');
        await fetchDataByTabValue();
    };

    const _handleFilter = async () => {
        await fetchDataByTabValue();
    };

    const onDelete = (row: IRow) => {
        certTabValue === LIST_VIEW_TYPES.CERTIFICATE
            ? setId(row['certificateId'])
            : setId(row['keyId']);
        DeleteCertificateModalStore.openModal();
    };

    const _handleDelete = async () => {
        if (certTabValue === LIST_VIEW_TYPES.CERTIFICATE) {
            await deleteCertificate(id);
        } else {
            await deleteCSR(id);
        }
    };

    const renderActions = (row: IRow, type: string) => {
        return (
            <>
                <StyledIcon
                    className="icon download"
                    disabled={isDownloading}
                    {...(type === LIST_VIEW_TYPES.CERTIFICATE
                        ? {
                              onClick: () => downloadCertificate(row['certificateId']),
                          }
                        : {
                              onClick: () => downloadCSR(row['keyId']),
                          })}>
                    <img src={DownloadIcon} alt={t('download')} />
                </StyledIcon>
                <StyledIcon
                    className="icon delete"
                    onClick={() => onDelete(row)}
                    disabled={
                        isDeleting ||
                        (row['expirationDate'] && new Date() <= new Date(row['expirationDate']))
                    }>
                    <img className="deleteCertificate" src={DeleteIcon} alt={t('delete')} />
                </StyledIcon>
            </>
        );
    };

    const certificatesHeader = [
        t('id'),
        t('serialNumber'),
        t('name'),
        t('format'),
        t('status'),
        t('expiry'),
        t('action'),
    ];
    const csrHeader = [t('id'), t('name'), t('format'), t('status'), t('action')];

    const certificatesCellTemplates = [
        (row: IRow) => cellConstructor.text({ row, keyId: 'certificateId', field: 'keyId' }),
        (row: IRow) =>
            cellConstructor.text({
                row,
                keyId: 'certificateId',
                field: 'serial',
            }),
        (row: IRow) =>
            cellConstructor.text({ row, keyId: 'certificateId', field: 'customizationName' }),
        (row: IRow) => cellConstructor.text({ row, keyId: 'certificateId', field: 'format' }),
        (row: IRow) => cellConstructor.text({ row, keyId: 'certificateId', field: 'status' }),
        (row: IRow) =>
            cellConstructor.text({
                row,
                keyId: 'certificateId',
                field: 'expirationDate',
                value: formatDate(row['expirationDate']),
            }),
        (row: IRow) =>
            cellConstructor.text({
                row,
                keyId: 'certificateId',
                field: 'actions',
                value: renderActions(row, LIST_VIEW_TYPES.CERTIFICATE),
            }),
    ];

    const csrCellTemplates = [
        (row: IRow) => cellConstructor.text({ row, keyId: 'keyId', field: 'keyId' }),
        (row: IRow) => cellConstructor.text({ row, keyId: 'keyId', field: 'name' }),
        (row: IRow) => cellConstructor.text({ row, keyId: 'keyId', field: 'format' }),
        (row: IRow) => cellConstructor.text({ row, keyId: 'keyId', field: 'status' }),
        (row: IRow) =>
            cellConstructor.text({
                row,
                keyId: 'keyId',
                field: 'actions',
                value: renderActions(row, LIST_VIEW_TYPES.CSR),
            }),
    ];

    const activeTab =
        certTabValue === LIST_VIEW_TYPES.CERTIFICATE ? (
            <TableWrapper
                classes={{
                    root: classes.tableWrapperRootCertificate,
                }}
                TableWrapperStore={CertificatesTableWrapperStore}
                header={certificatesHeader}
                disableRowSelect
                loading={loadingCertificate}
                cellTemplates={certificatesCellTemplates}
                noDataMessage={t('youNeedCerts')}
                serverSidePagination
                pageCallback={(currentPage) => getCertificates(currentPage - 1)}
            />
        ) : (
            <TableWrapper
                classes={{
                    root: classes.tableWrapperRootCSR,
                }}
                TableWrapperStore={CSRsTableWrapperStore}
                header={csrHeader}
                disableRowSelect
                loading={loadingCSR}
                cellTemplates={csrCellTemplates}
                serverSidePagination
                noDataMessage={t('youNeedCerts')}
                pageCallback={(currentPage) => getCSRs(currentPage - 1)}
            />
        );

    return (
        <div className="certificateLanding">
            <Grid container direction="column">
                {isAdmin && (
                    <Grid item xs={6} className="memberSearchBar">
                        <MemberSearchDropdown
                            MemberSearchDropdownStore={MemberSearchDropdownStore}
                        />
                    </Grid>
                )}
                <Grid item xs={7} md={4} lg={3}>
                    <ButtonWrapper
                        className="uploadCertificate"
                        disabled={!isMemberId}
                        onClick={sendToUploadCert}
                        startIcon={<img src={uploadIcon} />}>
                        {t('uploadCertificate')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <div className="tabsContainer">
                <Paper square>
                    <Tabs
                        value={certTabValue}
                        classes={{
                            flexContainer: classes.tabsflexContainer,
                        }}
                        onChange={handleCertTabChange}>
                        {allCertTabs.map((key) => (
                            <Tab
                                className="tab"
                                classes={{
                                    root: classes.rootTab,
                                }}
                                key={key}
                                label={t(key)}
                                value={key}
                            />
                        ))}
                    </Tabs>
                </Paper>
                <Grid container spacing={4}>
                    <Grid item xs={6} className="keyIdField">
                        <TextFieldWrapper
                            name="keyId"
                            label={t('id')}
                            type="text"
                            value={keyIdFilter[certTabValue as CertificateTabType]}
                            onChange={handleKeyIdChange}
                            hideOptional
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonWrapper
                            className="filterButton"
                            disabled={
                                !isMemberId || !keyIdFilter[certTabValue as CertificateTabType]
                            }
                            onClick={_handleFilter}>
                            {t('common:filter')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonWrapper
                            className="clearButton"
                            disabled={
                                !isMemberId || !keyIdFilter[certTabValue as CertificateTabType]
                            }
                            onClick={_handleClear}>
                            {t('common:clear')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
            {activeTab}
            <DeleteCertificateModal
                Store={DeleteCertificateModalStore}
                onSubmit={_handleDelete}
                title={t('deleteModalText')!}
                submitText={t('submitDelete')!}
                cancelText={t('cancelDelete')!}
                loading={isDeleting}
            />
            <DeleteCertificateErrorModal
                Store={DeleteCertificateErrorModalStore}
                onRetry={_handleDelete}
                title={t('deleteError', { type: certTabValue })!}
                retryText={t('tryAgain')!}
                cancelText={t('cancelError')!}
                loading={isDeleting}
            />
            <GenericErrorModal Store={GenericErrorStore} />
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CertificatesLanding));
