import { Divider, Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import UploadCertificateController from './UploadCertificate.controller';

// images
import uploadCompleteIcon from 'images/upload-complete.svg';
import uploadRequestIcon from 'images/upload-request.svg';

// constants
import { TYPE_THREE } from 'constants/memberTypes';

function UploadCertificate({ AppStore, CertificateManagementStore }) {
    const isTypeThree = AppStore.member.memberType === TYPE_THREE;
    const { t } = AppController.getTranslation(AppStore.scope, ['upload-certificate', 'common']);

    const {
        isCSRCreated,
        isRefund,
        isTpp,
        UploadCertificateStore,
        isAdmin,
    } = CertificateManagementStore;
    const {
        certFile,
        privateKeyFile,
        onCertificateDrop,
        onPrivateKeyDrop,
        invalidFileFormatInCertUpload,
        invalidFileFormatInPrivateKeyUpload,
        tooManyFilesInCertUpload,
        tooManyFilesInPrivateKeyUpload,
        invalidCert,
        invalidPrivateKey,
        rootStore,
        handleChange,
        certTypes,
        certType,
        lastCertFileUploaded,
        lastPrivateKeyFileUploaded,
        alias,
        certName,
        fetchCSRs,
        certificateSigningRequests,
        initCertTypes,
        loading,
    } = UploadCertificateStore;
    const isRegularUploadCertificate = !isRefund && !isTypeThree;

    useEffect(() => {
        if (!(isRefund || isTypeThree)) fetchCSRs(AppStore);
        if (isRefund) initCertTypes();
    }, []);

    function _onComplete() {
        return UploadCertificateController.nextStep(
            AppStore,
            CertificateManagementStore.UploadCertificateStore,
            isCSRCreated,
            isRefund,
        );
    }

    const hasCSRBeenCreated = useMemo(() => {
        if (!certType || isRefund) {
            return false;
        }

        return !!certificateSigningRequests.find((csr) => csr.keyType === certType);
    }, [certType, certificateSigningRequests]);

    function _getText(open, type) {
        const isCertificate = type === 'certificate';
        const isPrivateKey = type === 'private-key';
        const file = isCertificate ? lastCertFileUploaded : lastPrivateKeyFileUploaded;
        const errorText = isCertificate ? (
            <>
                {invalidFileFormatInCertUpload && t('invalidCertificateFormat')}
                {tooManyFilesInCertUpload && t('tooManyFiles')}
                {invalidCert && t('invalidCert')}
            </>
        ) : (
            <>
                {certType && !hasCSRBeenCreated && !file && (
                    <>
                        {t('privateKeyRequired')}
                        <br />
                    </>
                )}
                {invalidFileFormatInPrivateKeyUpload && t('invalidPrivateKeyFormat')}
                {tooManyFilesInPrivateKeyUpload && t('tooManyFiles')}
                {invalidPrivateKey && t('invalidPrivateKey')}
            </>
        );

        return (
            <div>
                {file ? (
                    <>
                        <img src={uploadCompleteIcon} />
                        <div className={'sectionTitle'}>
                            {t(
                                isCertificate
                                    ? 'uploadCertificateComplete'
                                    : 'uploadPrivateKeyComplete',
                            )}
                        </div>
                        <div>{file.name}</div>
                    </>
                ) : (
                    <>
                        <img src={uploadRequestIcon} />
                        <div className={'sectionTitle'}>
                            {isCertificate && t('dropCertificate')}
                            {isPrivateKey &&
                                t(hasCSRBeenCreated ? 'privateKeyNotRequired' : 'dropPrivateKey')}
                        </div>
                        {isPrivateKey && hasCSRBeenCreated && (
                            <div className={'sectionSubheader'}>
                                {t('removePrivateKeySuggestion')}
                            </div>
                        )}
                    </>
                )}
                {((isPrivateKey && !hasCSRBeenCreated) || isCertificate) && (
                    <div>
                        <span>{t('common:or')}</span>
                        &nbsp;
                        <span className={'link'} onClick={open}>
                            {t(file ? 'chooseDifferentFile' : 'chooseFile')}
                        </span>
                        <span className={`errorText error-text-${type}`}>{errorText}</span>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={'uploadCert'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={12} spacing={4}>
                        {isTypeThree && !isAdmin && !isRefund && (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        )}
                        <Grid item xs={12} lg={8}>
                            {!(isRefund || isTpp) && !isAdmin && (
                                <div className={'title'}>{t('sub')}</div>
                            )}
                            <div className={'description'}>
                                {!isTypeThree ? t('description') : t('type3Description')}
                            </div>
                        </Grid>
                        {!isTypeThree && (
                            <>
                                <Grid item xs={12} lg={8}>
                                    <Grid item xs={3} lg={2}>
                                        <SelectWrapper
                                            name="certType"
                                            label={t('selectCertType')}
                                            value={certType}
                                            onChange={handleChange}
                                            required>
                                            {certTypes.map((s) => (
                                                <MenuItem key={s} value={s}>
                                                    {t(s)}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>
                                    {certType && (
                                        <>
                                            <TextFieldWrapper
                                                className={'alias'}
                                                name="alias"
                                                type="text"
                                                label={
                                                    isRegularUploadCertificate
                                                        ? `${t('alias')} *`
                                                        : t('alias')
                                                }
                                                value={alias}
                                                onChange={handleChange}
                                                tooltipText={t('aliasLimit')}
                                                showTooltip
                                                required={isRegularUploadCertificate}
                                            />
                                            <div className={'certName'}>
                                                {`${t('completeCert')}: ${certName}`}
                                            </div>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>
                                        {certType ? (
                                            t(
                                                hasCSRBeenCreated
                                                    ? 'CSRHasBeenCreated'
                                                    : 'CSRHasNotBeenCreated',
                                            ).replace('{certType}', certType)
                                        ) : (
                                            <br />
                                        )}
                                    </strong>
                                </Grid>
                            </>
                        )}
                        <Grid container item xs={10}>
                            {!isTypeThree && (
                                <>
                                    <Grid item xs={5}>
                                        <Dropzone
                                            accept={['.rsa', '.key']}
                                            onDrop={onPrivateKeyDrop}
                                            noClick
                                            noKeyboard
                                            disabled={hasCSRBeenCreated || !certType}
                                            id="private-key-uploader">
                                            {({ getRootProps, getInputProps, open }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <div
                                                        id="private-key-upload-section"
                                                        className="uploadSection">
                                                        {_getText(open, 'private-key')}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                    <Grid item xs={1} />
                                </>
                            )}
                            <Grid item xs={!isTypeThree ? 5 : 8}>
                                <Dropzone
                                    accept=".pem"
                                    onDrop={onCertificateDrop}
                                    noClick
                                    noKeyboard
                                    id="certificate-uploader">
                                    {({ getRootProps, getInputProps, open }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div
                                                id="certificate-upload-section"
                                                className="uploadSection">
                                                {_getText(open, 'certificate')}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={8} className={'bottomSection'}>
                            <Divider />
                            <Grid container spacing={4}>
                                <Grid item xs={9} />
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        onClick={_onComplete}
                                        disabled={
                                            !certFile ||
                                            (!certType && !isTypeThree) ||
                                            (!hasCSRBeenCreated &&
                                                !privateKeyFile &&
                                                !isTypeThree) ||
                                            loading ||
                                            (!alias && isRegularUploadCertificate)
                                        }
                                        loading={loading}>
                                        {t(
                                            UploadCertificateController.getNextKey(
                                                AppStore.scope,
                                                isCSRCreated,
                                            ),
                                        )}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

UploadCertificate.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
    'BankConfigurationStore',
)(observer(UploadCertificate));
